<template>
  <v-dialog v-model="toggle" persistent width="30%">
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="loading"
          :canBack="lastTabs.length !== 0 && !loading"
          :isEdit="isEdit"
          :title="isEdit ? accountName : 'conta'"
          @onBack="closeTab()"
          @onClose="closeModal()"
        />

        <v-skeleton-loader type="article, actions" :loading="skLoader" :transition-group="'fade-transition'">
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :key="0" :value="'tab-0'">
              <div class="selectAccountTypeDiv">
                <v-row no-gutters>
                  <h3>Escolha o tipo da conta:</h3>
                </v-row>

                <v-row no-gutters v-for="(item, index) in accountTypes" :key="index">
                    <button class="accountTypeButton" @click="selectAccountType(index)" :disabled="bankList.length === 0">
                      <v-icon class="accountTypeButton-icon" large>{{icons[index]}}</v-icon>
                      {{item.name}}
                    </button>
                </v-row>
              </div>
            </v-tab-item>

            <v-tab-item :key="1" :value="'tab-1'">
              <div class="selectAccountTypeDiv">
                <v-row no-gutters>
                  <h3 style="margin-left: 16px;">Qual seu banco?</h3>
                </v-row>

                <v-row no-gutters style="margin-top: 16px;">
                  <v-autocomplete
                  v-model="selectedBank"
                  label="Escolha um banco"
                  prepend-icon="mdi-magnify"
                  :items="bankList"
                  clearable
                  outlined
                  filled
                  dense>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="data.item.icon">
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  </v-autocomplete>
                </v-row>
              </div>
            </v-tab-item>

            <v-tab-item :key="2" :value="'tab-2'">
              <div class="selectAccountTypeDiv">
                <v-row no-gutters style="justify-content: center;">
                  <v-avatar size="64">
                    <img :src="(selectedBank || {}).icon
                      || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'"/>
                  </v-avatar>
                </v-row>

                <v-row no-gutters style="margin-top: 16px;">
                  <v-text-field
                    label="Nome de identificação da conta"
                    v-model="accountName"
                    :error-messages="accountNameErrors"
                    :disabled="loading"
                    @blur="$v.accountName.$touch()"
                    outlined>
                  </v-text-field>
                </v-row>

                <v-row no-gutters style="margin-top: 16px;">
                  <v-currency-field
                    label="Saldo inicial"
                    v-model="balance"
                    prefix="R$"
                    :disabled="loading"
                    outlined
                  />
                </v-row>

                <v-row no-gutters style="margin-top: 16px;">
                  <v-text-field
                    label="Observação"
                    v-model="memo"
                    :disabled="loading"
                    outlined>
                  </v-text-field>
                </v-row>

                <OrganizzeModalFooter @onConfirm="enviar" :loading="loading"/>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-skeleton-loader>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions, mapGetters } from 'vuex'
import { isValidParam } from '@/utils/validationUtils'
import validation from '../validation'
import { required } from 'vuelidate/lib/validators'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { accountTypesEnum } from '@/utils/tempUtils'

const namespaceStore = 'organizzeFinanceiroContas'

export default {
  name: 'OrganizzeFinanceiroContasModalCadastro',

  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header'),
    OrganizzeModalFooter: () => import('@/views/components/organizze/modal/footer')
  },

  data: (() => ({
    toggle: false,
    icons: ['mdi-bank', 'mdi-account-box', 'mdi-wallet'],
    accountTypes: null,
    selectedAccountType: null,
    tab: 'tab-0',
    lastTabs: [],
    selectedBank: null,
    accountName: null,
    balance: 0,
    memo: null,
    loading: false,
    skLoader: true,
    isEdit: false,
    accountId: null
  })),

  created () {
    const self = this
    const types = accountTypesEnum()
    self.accountTypes = Object.keys(types).map(key => {
      return {
        id: key,
        name: types[key]
      }
    })
  },

  mounted () {
    const self = this

    Events.$on('modalCadastro::open', () => {
      self.toggle = true
    })

    Events.$on('modalCadastro::edit', data => {
      self.toggle = true
      self.isEdit = true
      self.accountId = data.account.id
      self.accountName = data.account.name
      self.selectedBank = data.account.Institutions
      self.selectedAccountType = data.account.type
      self.balance = parseFloat(data.account.initialBalance).toFixed(2)
      self.memo = data.account.memo
      self.tab = data.tab
    })

    self.getBankList().then(() => {}).catch(e => { console.error(e) }).finally(() => { self.skLoader = false })
  },

  computed: {
    ...mapGetters(namespaceStore, ['bankList']),
    ...validation
  },

  watch: {
    selectedBank () {
      const self = this
      if (isValidParam(self.selectedBank)) {
        self.accountName = self.selectedBank.name
        self.openTab('tab-1', 'tab-2')
      }
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['getBankList', 'submit']),
    openTab (lastTab, newTab) {
      const self = this
      self.lastTabs.push(lastTab)
      self.tab = newTab
    },

    closeTab () {
      const self = this
      self.tab = self.lastTabs[self.lastTabs.length - 1] || 'tab-0'
      if (self.tab.length > 0) self.lastTabs.pop()
      self.clearItems()
    },

    closeModal () {
      const self = this
      self.tab = 'tab-0'
      self.lastTabs = []
      self.isEdit = false
      self.toggle = false
      self.clearItems()
    },

    clearItems () {
      const self = this
      self.selectedBank = null
      self.accountName = null
      self.balance = 0
      self.memo = null
      self.selectedAccountType = null
    },

    enviar () {
      const self = this

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      self.loading = true

      const data = {
        name: self.accountName,
        type: self.selectedAccountType,
        institutionId: (self.selectedBank || {}).id || null,
        institution: (self.selectedBank || {}).id || null,
        initialBalance: self.balance,
        memo: self.memo,
        isEdit: self.isEdit
      }

      if (self.isEdit) data.id = self.accountId

      self.submit(data).then(() => {
        if (!self.isEdit) successSnackbar('Conta adicionada com sucesso.')
        else successSnackbar('Conta editada com sucesso.')
        Events.$emit('index::getAccounts')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.loading = false
      })
    },

    selectAccountType (index) {
      const self = this
      self.selectedAccountType = self.accountTypes[index].id

      self.openTab('tab-0', `tab-${index === 2 ? 2 : 1}`)
    }
  },

  validations () {
    return {
      accountName: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .v-btn { text-transform: uppercase; }

  .accountTypeButton {
    color: $colorAccent;
    border: 3px solid transparent;
    border-radius: 16px;
    padding: 8px;
    margin: 8px 0px 8px;
    width: 100%;
    text-align: left;

    &-icon {
      color: $colorAccent;
    }

    &:hover {
      color: $colorPrimary;
      border: 3px solid $colorPrimary;

      .accountTypeButton-icon {
        color: $colorPrimary;
      }
    }
  }

  .selectAccountTypeDiv { margin: 0px 16px; }
</style>